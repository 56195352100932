import React, { useState } from "react"
import Layout from "components/layout"

import ContactComplete from "../../../components/contact/complete";
import Seo from "../../../components/seo";
import OwnersSupportForm from "../../../components/contact/owners-support/form";
import OwnersSupportConfirm from "../../../components/contact/owners-support/confirm";

const OwnersSupportContactPage = () => {
    const [contactState, setContactState] = useState({
        step: 'form',
    })
    const [formData, setFormData] = useState({
        comment: '',
        company_name: '',
        last_name: '',
        first_name: '',
        last_name_ruby: '',
        first_name_ruby: '',
        email: '',
        tel: '',
        contact_type: '',
        section_name: '',
    })

    const handleOnNextPage = data => {
        setFormData({
            ...formData,
            comment: data.comment,
            company_name: data.company_name ?? "",
            last_name: data.last_name,
            first_name: data.first_name,
            last_name_ruby: data.last_name_ruby,
            first_name_ruby: data.first_name_ruby,
            email: data.email,
            tel: data.tel ?? "",
            contact_type: data.contact_type ?? "",
            section_name: data.section_name ?? ""
        });

        let nextStep = 'form';
        if (contactState.step === 'form'){
            nextStep = 'confirm';
        }else if (contactState.step === 'confirm'){
            nextStep = 'complete';
        }

        setContactState({
            ...contactState,
            step: nextStep,
        })
    }
    const handleOnBackPage = () => {
        let prevStep = 'confirm';
        if (contactState.step === 'confirm'){
            prevStep = 'form';
        }
        setContactState({
            ...contactState,
            step: prevStep,
        })
    }

    return (
        <Layout>
            <div className="contact-page">
                <div className="contents">
                    <OwnersSupportForm
                        contactState={contactState}
                        handleOnNextPage={handleOnNextPage}
                    />
                    <OwnersSupportConfirm
                        contactState={contactState}
                        handleOnNextPage={handleOnNextPage}
                        handleOnBackPage={handleOnBackPage}
                        formData={formData}
                    />
                    <ContactComplete
                        contactState={contactState}
                    />
                </div>
            </div>
        </Layout>
    )
}

export const Head = () => (
    <Seo
        title="建物・物件 管理代行サービス（定期巡回）について"
        description="OKULAB（オクラボ）のお問い合わせページです。"
    />
)
export default OwnersSupportContactPage
